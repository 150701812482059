declare global {
  interface Window {
    dtrum?: {
      reportError: (error: string | Error) => void;
      endSession: () => void;
      sendSessionProperties: (undefined: undefined, dates: Record<string, Date>, strings: Record<string, string>, numbers: Record<string, number>) => void;
      enterAction: (actionName: string) => string | undefined;
      addActionProperties: (actionId: string, undefined: undefined, dates: Record<string, Date>, strings: Record<string, string>, numbers: Record<string, number>) => void; 
      leaveAction: (actionId: string) => void;
      identifyUser: (userId: string) => void;
    };
  }
}

export const initializeDynatrace = () => {
  const dynatraceUrl = process.env.NEXT_PUBLIC_DYNATRACE_URL;
  if (!dynatraceUrl) {
    return false;
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.crossOrigin = "anonymous";
  script.src = dynatraceUrl;
  document.head.appendChild(script);

  return true;
};

const splitProperties = (properties: Record<string, unknown>) => {
  const dates: Record<string, Date> = {};
  const strings: Record<string, string> = {};
  const numbers: Record<string, number> = {};

  Object.entries(properties).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];

    if (value instanceof Date) {
      dates[key] = value;
    } else if (typeof value === "number") {
      numbers[key] = value;
    } else if (typeof value === "string") {
      strings[key] = value;
    }
  });

  return {
    dates,
    strings,
    numbers
  }
}

export const endDynatraceSession = () => {
  if (!window.dtrum) {
    return;
  }

  window.dtrum.endSession();
};

export const trackDynatraceSession = (properties: Record<string, unknown>) => {
  if (!window.dtrum) {
    return;
  }

  const {
    dates,
    strings,
    numbers,
  } = splitProperties(properties);

  window.dtrum.sendSessionProperties(undefined, dates, strings, numbers);
};

export const trackDynatraceAction = (
  actionName: string,
  properties?: Record<string, unknown>,
) => {
  if (!window.dtrum) {
    return;
  }

  const actionId = window.dtrum.enterAction(actionName);
  if (actionId === undefined) {
    return;
  }

  if (properties) {
    const {
      dates,
      strings,
      numbers,
    } = splitProperties(properties);

    window.dtrum.addActionProperties(
      actionId,
      undefined,
      dates,
      strings,
      numbers,
    );
  }

  window.dtrum.leaveAction(actionId);
};

export const trackDynatraceError = (error: unknown) => {
  if (!window.dtrum) {
    return;
  }

  if (typeof error === "string" || error instanceof Error) {
    window.dtrum.reportError(error);
  }
}

export const trackDynatraceUser = (userId: string) => {
  if (!window.dtrum) {
    return;
  }

  window.dtrum.identifyUser(userId);
};
