import {
  endDatadogSession,
  initializeDatadog,
  trackDatadogAction,
  trackDatadogContext,
  trackDatadogError,
  trackDatadogUser
} from "./datadog";
import {
  initializeDynatrace,
  endDynatraceSession,
  trackDynatraceSession,
  trackDynatraceAction,
  trackDynatraceError,
  trackDynatraceUser
} from "./dynatrace";

export type RumProviderFlag = "off" | "dynatrace" | "datadog" | "both";

class RumProvider {
  private trackActionInternal: (
    name: string,
    properties?: Record<string, unknown>,
  ) => void = () => {};
  private trackSessionInternal: (properties: Record<string, unknown>) => void =
    () => {};
  private trackErrorInternal: (error: unknown, properties?: Record<string, unknown>) => void = () => {};
  private trackUserInternal: (id: string) => void = () => {};
  private endSessionInternal: () => void = () => {};

  /**
   * @param featureFlag A flag indicating which RUM integration to use
   * @returns True if the initialization was completed successfully
   */
  initialize(featureFlag: RumProviderFlag) {
    if (featureFlag === "datadog") {
      const initialized = initializeDatadog();
      if (!initialized) {
        return false;
      }

      this.trackActionInternal = trackDatadogAction;
      this.trackSessionInternal = trackDatadogContext;
      this.trackErrorInternal = trackDatadogError;
      this.trackUserInternal = trackDatadogUser
      this.endSessionInternal = endDatadogSession;

      return true;
    } else if (featureFlag === "dynatrace") {
      const initialized = initializeDynatrace();
      if (!initialized) {
        return false;
      }

      this.trackActionInternal = trackDynatraceAction;
      this.trackSessionInternal = trackDynatraceSession;
      this.trackErrorInternal = trackDynatraceError;
      this.trackUserInternal = trackDynatraceUser;
      this.endSessionInternal = endDynatraceSession;

      return true;
    } else if (featureFlag === "both") {
      const initialized = initializeDatadog() && initializeDynatrace();
      if (!initialized) {
        return false;
      }

      this.trackActionInternal = (name, properties) => {
        trackDatadogAction(name, properties);
        trackDynatraceAction(name, properties);
      };
      this.trackSessionInternal = (properties) => {
        trackDatadogContext(properties);
        trackDynatraceSession(properties);
      };
      this.trackErrorInternal = (error, properties) => {
        trackDatadogError(error, properties);
        trackDynatraceError(error);
      };
      this.trackUserInternal = (id) => {
        trackDatadogUser(id);
        trackDynatraceUser(id);
      };
      this.endSessionInternal = () => {
        endDatadogSession();
        endDynatraceSession();
      };

      return true;
    }

    return false;
  }

  trackAction(name: string, properties?: Record<string, unknown>) {
    this.trackActionInternal(name, properties);
  }

  trackSession(properties: Record<string, unknown>) {
    this.trackSessionInternal(properties);
  }

  trackError(error: unknown, properties?: Record<string, unknown>) {
    this.trackErrorInternal(error, properties);
  }

  trackUser(id: string) {
    this.trackUserInternal(id);
  }

  endSession() {
    this.endSessionInternal();
  }
}

// Due to the way ES6 modules resolve, this will be a singleton by default
// There must only one be instance so the correct internal methods are always called
export const rumProvider = new RumProvider();