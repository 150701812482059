import { QueryKey, UseQueryResult, useQuery } from "@tanstack/react-query";
import { OnboardingMerchantId } from "merchant-core";
import {
  OnboardingMerchant,
} from "../../models/onboardingMerchant";
import { merchantOnboardingHubApiUrl } from "../../constants/apiUrls";
import { useMerchantUser } from "../useMerchantUser";
import { client } from "../../api/client";

export const onboardingMerchantQueryKey = (
  merchantId: OnboardingMerchantId,
): QueryKey => {
  return ["onboarding-merchant", { merchantId }];
};

export const onboardingMerchantIsSignedQueryKey = (
  merchantId: OnboardingMerchantId,
): QueryKey => {
  return ["onboarding-merchant-is-signed", { merchantId }];
};

const GET_ONBOARDING_MERCHANT_URL = (
  merchantId: OnboardingMerchantId,
): string => {
  return `${merchantOnboardingHubApiUrl}/${merchantId}/onboarding-hub`;
};

export interface GetOnboardingMerchantResponse {
  onboardingMerchant: OnboardingMerchant;
}

const getOnboardingMerchant = async (
  omId: OnboardingMerchantId,
): Promise<OnboardingMerchant> => {
  const response = await client.get<GetOnboardingMerchantResponse>(
    GET_ONBOARDING_MERCHANT_URL(omId),
  );
  return response.data?.onboardingMerchant;
};

export const useOnboardingMerchant = (): UseQueryResult<
  OnboardingMerchant,
  Error
> => {
  const { merchantId } = useMerchantUser();

  return useQuery<unknown, Error, OnboardingMerchant>({
    queryKey: onboardingMerchantQueryKey(merchantId),
    queryFn: () => getOnboardingMerchant(merchantId),
  });
};

/**
 * This hook checks whether the merchant has already signed the MSA.
 * A different QueryKey is used to prevent the user from being redirected 
 * before clicking the "Continue" button, ensuring they have time to read 
 * the message displayed after receiving the response.
 */
export const useOnboardingMerchantQueryIsSigned = (): UseQueryResult<
  OnboardingMerchant,
  Error
> => {
  const { merchantId } = useMerchantUser();

  return useQuery<unknown, Error, OnboardingMerchant>({
    queryKey: onboardingMerchantIsSignedQueryKey(merchantId),
    queryFn: () => getOnboardingMerchant(merchantId),
  });
};
