/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogRum } from "@datadog/browser-rum";
import {
  getSampleRate,
  parseBoolean,
  getDomains,
  validatePrivacyLevel,
} from "./utils";

export const initializeDatadog = () => {
  const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
  const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  if (!applicationId || !clientToken) {
    return false;
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: "us3.datadoghq.com",
    env: process.env.NEXT_PUBLIC_ENV,
    service: process.env.NEXT_PUBLIC_APPLICATION_NAME,
    sessionSampleRate: getSampleRate(
      process.env.NEXT_PUBLIC_DD_SAMPLE_RATE,
      100,
    ),
    trackUserInteractions: parseBoolean(
      process.env.NEXT_PUBLIC_TRACK_USER_INTERACTION,
    ),
    useCrossSiteSessionCookie: parseBoolean(
      process.env.NEXT_PUBLIC_USE_CROSS_SITE_SESSION_COOKIE,
    ),
    useSecureSessionCookie: parseBoolean(
      process.env.NEXT_PUBLIC_USE_SECURE_SESSION_COOKIE,
    ),
    silentMultipleInit: parseBoolean(
      process.env.NEXT_PUBLIC_SILENT_MULTIPLE_INIT,
    ),
    allowedTracingOrigins: getDomains(
      process.env.NEXT_PUBLIC_DATADOG_SERVICE_DOMAINS,
    ),
    sessionReplaySampleRate: getSampleRate(
      process.env.NEXT_PUBLIC_SESSION_REPLAY_SAMPLE_RATE,
      undefined,
    ),
    trackResources: parseBoolean(process.env.NEXT_PUBLIC_TRACK_RESOURCES),
    trackLongTasks: parseBoolean(process.env.NEXT_PUBLIC_TRACK_LONG_TASK),
    trackFrustrations: parseBoolean(process.env.NEXT_PUBLIC_TRACK_FRUSTRATIONS),
    defaultPrivacyLevel: validatePrivacyLevel(
      process.env.NEXT_PUBLIC_DEFAULT_PRIVACY_LEVEL,
    ),
  });

  if (process.env.NEXT_PUBLIC_DD_START_RECORDING === "true") {
    datadogRum.startSessionReplayRecording();
  }

  return true;
};

export const trackDatadogAction = (
  name: string,
  properties?: Record<string, any>,
): void => {
  datadogRum.addAction(name, properties);
};

/**
 * Adds a separate context to Datadog for each entry in the properties object
 * @param properties A map of context names (key) to context objects (value)
 */
export const trackDatadogContext = (properties: Record<string, any>): void => {
  Object.entries(properties).forEach((entry) => {
    datadogRum.addRumGlobalContext(entry[0], entry[1]);
  });
};

export const trackDatadogError = (
  error: unknown,
  properties?: Record<string, any>,
) => {
  datadogRum.addError(error, properties);
};

export const endDatadogSession = () => {
  datadogRum.clearGlobalContext();
  datadogRum.clearUser();
};

export const trackDatadogUser = (id: string) => {
  datadogRum.setUser({id: id});
};
