import { useEffect, useRef, useState } from "react";
import { useMerchantUser } from "../hooks/useMerchantUser";
import { useOnboardingMerchant } from "../hooks/api/useOnboardingMerchant";
import { rumProvider } from "rum-provider";

export const RumInitializer = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const hasInitialized = useRef(false);

  const { merchantId } = useMerchantUser();
  const { data: merchant } = useOnboardingMerchant();

  // Initialization
  useEffect(() => {
    if (hasInitialized.current) {
      return;
    }

    const success = rumProvider.initialize("dynatrace");
    setIsEnabled(success);
    hasInitialized.current = true;
  }, [hasInitialized, setIsEnabled]);

  // User identification
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (merchantId) {
      rumProvider.trackUser(merchantId);
    }
  }, [isEnabled, merchantId]);

  // Session/context
  useEffect(() => {
    if (!merchantId) {
      return;
    }

    const businessInfo = merchant?.businessInfo;
    rumProvider.trackSession({
      merchantid: merchantId,
      merchanttier: businessInfo?.merchantTier,
      businesstype: businessInfo?.businessType,
      annualrevenue: businessInfo?.annualRevenue,
      averageordervalue: businessInfo?.averageOrderValue,
      industry: businessInfo?.industry,
      secondaryindustry: businessInfo?.secondaryIndustry,
    });
  }, [merchantId, merchant?.businessInfo]);

  return null;
};