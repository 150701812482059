import { DefaultPrivacyLevel } from "@datadog/browser-rum";

export const getDomains = (domains?: string) => {
  if (!domains) return [];

  return domains
    .trim()
    .split(" ")
    .map((domain) => {
      // Include all domains listed in the env file.
      return new RegExp(`https://.*.${domain}`);
    });
};

export const getSampleRate = (
  env: string | undefined,
  defaultValue: number | undefined,
): number | undefined => {
  if (env) {
    const parsedValue = parseInt(env, 10);
    return isNaN(parsedValue) ? undefined : parsedValue;
  }
  return defaultValue;
};

export const parseBoolean = (env: string | undefined): boolean | undefined => {
  if (env === "true") {
    return true;
  } else if (env === "false") {
    return false;
  }
  return undefined;
};

export const validatePrivacyLevel = (
  env: string | undefined,
): DefaultPrivacyLevel => {
  return ["allow", "mask", "mask-user-input"].includes(
    env as DefaultPrivacyLevel,
  )
    ? (env as DefaultPrivacyLevel)
    : "mask-user-input";
};